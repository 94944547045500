<template>
  <div class="component-submenu mb-2p">
    <div class="ul">
      <div class="li" v-if="options.edit">
        <button class="btn" @click="funEdit" :disabled="editDisabled()" v-tippy="{ content: '编辑', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/edit.svg">
        </button>
      </div>

      <div class="li" v-if="options.del">
        <button class="btn" @click="funDelete" :disabled="multipleDisabled" v-tippy="{ content: '删除', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/delete.svg">
        </button>
      </div>
      <div class="li" v-if="options.copy">
        <button class="btn" @click="funCopy" :disabled="multipleDisabled" v-tippy="{ content: '复制', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/copy.svg">
        </button>
      </div>
      <div class="li" v-if="options.print">
        <button class="btn" @click="funPrint" :disabled="singleDisabled" v-tippy="{ content: '打印', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/print.svg">
        </button>
      </div>
      <!-- <div class="li">
        <button class="btn" @click="funImport">
          <img class="icon-16" src="/static/images/icon/import.svg">
        </button>
      </div> -->
      <div class="li" v-if="options.export">
        <button class="btn" @click="funExport" :disabled="multipleDisabled" v-tippy="{ content: '导出', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/export.svg">
        </button>
      </div>

      <div class="li" v-if="options.attributes">
        <button class="btn" @click="funAttributes" :disabled="singleDisabled" v-tippy="{ content: '属性', theme: 'light' }">
          <img class="icon-16" src="/static/images/icon/attributes.svg">
        </button>
      </div>

      <slot name="left-menu"></slot>

    </div>
  </div>
</template>

<script>
let token = sessionStorage.getItem('Authorization');
let tokenJson = JSON.parse(token)
let permissions = tokenJson.permissions.split(',')

export default {
  name: 'SubMenu',

  props: {
    labels: Array, //父组件把id传过来
    options: Object,
  },

  data() {
    return {
      singleDisabled: true,
      multipleDisabled: true,
    }
  },

  watch: {
    labels() {
      //单选
      if (this.labels.length == 1) {
        this.singleDisabled = false
      } else {
        this.singleDisabled = true
      }

      //多选
      if (this.labels.length > 0) {
        this.multipleDisabled = false
      } else {
        this.multipleDisabled = true
      }
    },
  },

  computed: {
    editDisabled() {
      return () => {
        if (this.options.edit && this.options.edit.disabled == 'singleDisabled') {
          return this.singleDisabled
        } else {
          return this.multipleDisabled
        }
      }
    }
  },

  methods: {

    funEdit() {
      console.log(permissions)
      console.log(this.labels)
      let _this = this
      let success = 0;
      if (this.options.edit.path) {
        this.options.edit.path.forEach(function (item) {
          if (item.type == _this.labels[0].type && permissions.includes(item.permissions)) {
            _this.$router.push({name: item.fetch, params: {id: _this.labels[0].id}})
            success = 1;
            return false;
          }
        })
        if (!success) {
          _this.$toast.error('无权限操作')
        }

      } else if (this.options.edit.permissions && !permissions.includes(this.options.edit.permissions)) {
        this.$toast.error('无权限操作')
        return false;
      } else if (this.options.edit && typeof this.options.edit.fun === 'function') {
        this.options.edit.fun()
      } else {
        if (this.labels.length > 0) {
          try {
            if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
            if (!this.options.edit || !this.options.edit.fetch) throw '[参数错误] "Edit fetch"参数为空'

            let id = []
            this.labels.forEach(function(item) {
              id.push(item.id)
            })
            this.$router.push({name: this.options.edit.fetch, params: {id: id}})
          }
          catch(err) {
            console.error(err)
          }
        }
      }
     
    },

    funDelete() {
      if (this.options.del.permissions && !permissions.includes(this.options.del.permissions)) {
        this.$toast.error('无权限操作')
        return false;
      }

      if (this.options.del && typeof this.options.del.fun === 'function') {
        this.options.del.fun()
      } else {
        try {
          if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
          if (!this.options.del || !this.options.del.fetch) throw '[参数错误] "Delete fetch"参数为空';

          let _this = this
          let length = this.labels.length
          let id = []

          this.labels.forEach(function(item) {
            id.push(item.id)
          })

          this.$vfm.show({
            component: 'ShowMessage',
            on: {
              confirm(close) {
                close()
                _this.$axios.post(_this.options.del.fetch,
                  {
                    id: id
                  },
                  {
                    headers: {
                      token: JSON.parse(sessionStorage.Authorization).token
                    }
                  }
                )
                .then(res => {
                  if (res.status == 200) {
                    _this.$emit('call', {
                      function: 'del',
                      res: res.data
                    })
                    _this.$toast.success(res.data.message)
                  }
                })
                .catch(error => {
                  console.error('error:', error)
                })
              },
              cancel(close) {
                console.log('取消')
                close()
              },
              closed() {
                console.log('@closed')
              }
            },
            slots: {
              title: '提示',
              default: '确定要删除 '+length+' 项数据吗？'
            }
          })
        }
        catch(err) {
          console.error(err)
        }
      }
    },

    funCopy() {
      if (this.options.copy.permissions && !permissions.includes(this.options.copy.permissions)) {
        this.$toast.error('无权限操作')
        return false;
      }

      if (this.options.copy && typeof this.options.copy.fun === 'function') {
        this.options.copy.fun()
      } else {
        try {
          if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
          if (!this.options.copy || !this.options.copy.fetch) throw '[参数错误] "Copy fetch"参数为空';

          let _this = this
          let length = this.labels.length
          let id = []

          this.labels.forEach(function(item) {
            id.push(item.id)
          })

          this.$vfm.show({
            component: 'ShowMessage',
            on: {
              confirm(close) {
                close()

                _this.$axios.post(_this.options.copy.fetch,
                  {
                    id: id
                  },
                  {
                    headers: {
                      token: JSON.parse(sessionStorage.Authorization).token
                    }
                  }
                )
                .then(res => {
                  if (res.status == 200) {
                    _this.$emit('call', {
                      function: 'copy',
                      res: res.data
                    })
                    _this.$toast.success(res.data.message)
                    console.log(res.data)
                  }
                })
                .catch(error => {
                  console.error('error:', error)
                })
              },
              cancel(close) {
                console.log('取消')
                close()
              },
              closed() {
                console.log('@closed')
              }
            },
            slots: {
              title: '提示',
              default: '确定要复制 '+length+' 项数据吗？'
            }
          })

        }
        catch(err) {
          console.error(err)
        }
      }
    },

    funPrint() {
      if (this.options.print && typeof this.options.print.fun === 'function') {
        this.options.print.fun()
      } else {
        try {
          if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
          if (!this.options.print || !this.options.print.fetch) throw '[参数错误] "Print fetch"参数为空';
          
          this.$router.push({name: this.options.print.fetch, params: {id: this.labels[0].id}})
        }
        catch(err) {
          console.error(err)
        }
      }
    },

    funImport() {
      if (this.options.import && typeof this.options.import.fun === 'function') {
        this.options.import.fun()
      } else {
        alert('导入')
      }
    },

    funExport() {
      if (this.options.export && typeof this.options.export.fun === 'function') {
        this.options.export.fun()
      } else {
        try {
          if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
          if (!this.options.export || !this.options.export.fetch) throw '[参数错误] "Export fetch"参数为空';

          let _this = this
          let length = this.labels.length
          let id = []

          this.labels.forEach(function(item) {
            id.push(item.id)
          })

          this.$vfm.show({
            component: 'ShowMessage',
            on: {
              confirm(close) {
                close()

                window.location.href = _this.options.export.fetch+'?id='+id+'&token='+JSON.parse(sessionStorage.Authorization).token
                
              },
              cancel(close) {
                console.log('取消')
                close()
              },
              closed() {
                console.log('@closed')
              }
            },
            slots: {
              title: '提示',
              default: '确定要导出 '+length+' 项数据吗？'
            }
          })
        }
        catch(err) {
          console.error(err)
        }
      }
    },

    funAttributes() {
      if (this.options.attributes && typeof this.options.attributes.fun === 'function') {
        this.options.attributes.fun()
      } else {
        try {
          if (this.labels.length == 0) throw '[数据错误] "Labels Array"为空'
          if (!this.options.attributes || !this.options.attributes.fetch) throw '[参数错误] "Attributes fetch"参数为空';
          
          this.$router.push({name: this.options.attributes.fetch, params: {id: this.labels[0].id}})
        }
        catch(err) {
          console.error(err)
        }
      }
    },

  }
}
</script>

<style scoped>


</style>
