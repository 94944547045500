<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>期初库存</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>

    </TopMenu>

    <SubMenu
      :labels="check"
      :options="submenu"
    >
      <template v-slot:left-menu>

        <div class="li ml-auto">
          <div class="input-group flex-1 mr-2p h-100">
            <span class="input-group-text">
              <img class="icon-16" src="/static/images/icon/search.svg">
            </span>
            <input type="text" class="form-control font-12" placeholder="搜索产品" v-model="submenu.searchProduct.value" @change="_search">
            <button class="btn btn-close" v-show="submenu.searchProduct.value" @click="_clearSearchProduct()">
              <img class="icon-12" src="/static/images/icon/close.svg">
            </button>
          </div>
        </div>

      </template>
    </SubMenu>

    <div class="content bg-25 h-100">
        <div class="flex-list hr w-100">
          <div style="width:20%;">名称</div>
          <div style="width:20%;">规格</div>
          <div style="width:20%;">库存数量</div>
          <div style="width:20%;">总价</div>
          <div style="width:20%;">平均单价</div>
        </div>

        <div class="flex-list hr w-100 btn-default hover" v-for="(data, index) in datas.product" :key="data.id">
          <div style="width:20%;">{{ data.name }}</div>
          <div style="width:20%;">{{ data.specification }}</div>
          <div style="width:20%;">
            <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="data.initial_quantity">
          </div>
          <div style="width:20%;">
            <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="data.initial_price">
          </div>
          <div style="width:20%;">
            <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" :value="$math.Div(data.initial_price, data.initial_quantity)" @change="averagePrice($event, index)">
          </div>
          
        </div>

    </div>

    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="totalPage"
        active-color="#004bff"
        @update:modelValue="getDatas($event)"
      />
    </div>
  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'StockTaking',

  data() {
    return {
      loading: true,
      datas: [],
      currentPage: 1,
      totalPage: 1,
      allowAttack: false,
      submenu: {
        searchProduct: {
          value: '',
        },
      }
    }
  },

  mounted() {
    console.log(this.$options.name+' 挂载')

    this.getDatas()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    SubMenu
  },

  methods: {
    getDatas(page, search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let p = page ? page : 1
        _this.$axios.get('/api/product', {
          headers: {
            token: JSON.parse(sessionStorage.Authorization).token
          },
          params: {
            page: p,
            take: 20,
            search: search
          }
        })
        .then(res => {
          resolve(res);

          if (res.status == 200) {
            if (res.data) {

              res.data.product.forEach(function(item) {
                item.real_quantity = null
              })

              console.log(res.data)
              this.datas = res.data
              this.currentPage = res.data.current_page
              this.totalPage = res.data.total_page
            }
            this.loading = false
          }
          //console.log(res)
        })
        .catch(error => {
          reject(error)
          console.log('error:', error)
        })
      });
    },

    averagePrice(e, index) {
      this.datas.product[index].initial_price = this.$math.Mul(e.target.value, this.datas.product[index].initial_quantity)
    },

    //子组件方法
    _search() {
      let search = this.submenu.searchProduct.value
      this.getDatas(null, search)
    },

    _clearSearchProduct() {
      this.submenu.searchProduct.value = '';
      this._search()
    },

    save() {
      let _this = this
      this.allowAttack = true
      return new Promise((resolve, reject) => {
        try {
          
          _this.$axios.post('/api/product/save-initial',
            {
              data: _this.datas
            },
            {
              headers: {
                token: JSON.parse(sessionStorage.Authorization).token
              }
            }
          )
          .then(res => {
            _this.allowAttack = false

            if (res.status == 200) {
              resolve(res);
              console.log(res.data)
              if (res.data.success) {
                this.$toast.success(res.data.message)
              } else {
                this.$toast.error(res.data.message)
              }
              
            }
          })
          .catch(error => {
            reject(error)
            _this.allowAttack = false
            console.error('error:', error)
          })
         
        } catch(error) {
          console.error(error)
          _this.allowAttack = false
          this.$toast.error(error)
        }
      })
    },

  },
  
}
</script>

<style scoped>
.active {
  background-color: #004bff !important;
}

.flex-list {
  padding: 8px 16px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}

.btn-close {
  padding: 6px;
  border-radius: 100%;
}

</style>
